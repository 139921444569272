
import img1 from '../images/pakat.png'
import img2 from '../images/surauDatoWan.png'
import img3 from '../images/masjidklanaDemo.png'
import img4 from '../images/azzein.png'
import img5 from '../images/widad.png'
import img6 from '../images/gedung.png'
import img7 from '../images/darulUlum.png'
import img8 from '../images/mawarku.png'
import img9 from '../images/darulHuda.png'
import img10 from '../images/SendayanDemo.png'
import img11 from '../images/sembulanmosque.png'
import img12 from '../images/sglong.png'

import avtd3 from '../images/contactIcon/profile.png'
import avtd4 from '../images/contactIcon/mail.png'
import avtd5 from '../images/contactIcon/tel.png'

const dataBlog = [
    // {
    //     id: 1,
    //     img: img1,
    //     title: 'PAKAT',
    //     slug:"PAKAT",
    //     desc: 'Pertubuhan Kebajikan Am Taman Dato Wan (PAKAT) adalah sebuah organisasi yang terlibat dalam pengurusan kebajikan am, khairat kematian dan pengurusan Surau Taman Dato Wan. PAKAT juga bergiat aktif menjalankan aktiviti-aktiviti keagamaan i.e. mengelola program-program kuliah mingguan dan pengajian Al-Quran kepada masyarakat setempat. Selain daripada itu PAKAT juga menjalankan aktiviti kemasyarakatan didalam usaha memastikan kesejahteraan warga setempat terutamanya ahli-ahli PAKAT.',
    //     addDesc: 'Bermula tahun 2023, PAKAT akan berusahasama dengan syarikat teknologi maklumat DagangTEK Sdn Bhd bagi melaksanakan perkhidmatan Infaq, Waqaf, Khairat dan lain-lain secara digital melalui platform applikasi mudah alih AL-Jariyah.',
    //     contact:[
    //         {
    //             id: 1,
    //             avt: avtd3,
    //             title:'Nama Pegawai',
    //             item: 'TUAN HAJI ZAKI MOHD YUSOF',
    //         },

    //         {
    //             id:2,
    //             avt: avtd4,
    //             title:'Email',
    //             item :'surautamandatowan@gmail.com',
    //         },
    //         {
    //             id: 3,
    //             avt: avtd5,
    //             title:'No. Telefon',
    //             item :'019-307 9453',

    //         }
    //     ],
    //     jawatan:[
            
    //     ],
    //     news:[
           
    //     ],
    //     state:'Seremban',
    //     address :'No. 12A, 70400 Negeri Sembilan, Seremban'
    // },
    // {
    //     id: 2,
    //     img: img2,
    //     title: 'SURAU TAMAN DATO WAN',
    //     slug:"SURAU-TAMAN-DATO-WAN",
    //     desc: 'Surau Taman Dato Wan adalah sebuah organisasi yang terlibat dalam pengurusan kebajikan am, khairat kematian, pengurusan kariah setempat dan juga pengurusan dalaman surau. Surau ini juga bergiat aktif menjalankan aktiviti-aktiviti keagamaan (mengelola program-program kuliah mingguan dan pengajian Al-Quran) dan aktiviti kemasyarakatan.',
    //     addDesc: 'Selain daripada itu Surau Taman Dato Wan juga menjalankan aktiviti kemasyarakatan didalam usaha memastikan kesejahteraan warga setempat.',
    //     contact:[
    //         {
    //             id: 1,
    //             avt: avtd3,
    //             title:'Nama Pegawai',
    //             item: 'TUAN HAJI ZAKI MOHD YUSOF',
    //         },

    //         {
    //             id:2,
    //             avt: avtd4,
    //             title:'Email',
    //             item :'surautamandatowan@gmail.com',
    //         },
    //         {
    //             id: 3,
    //             avt: avtd5,
    //             title:'No. Telefon',
    //             item :'019-307 9453',

    //         }
    //     ],
    //     jawatan:[
            
    //     ],
    //     news:[
            
    //     ],
    //     state:'Seremban',
    //     address :'No. 12A, 70400 Negeri Sembilan, Seremban'
    // },
    // {
    //     id: 3,
    //     img: img3,
    //     title: 'MASJID DATO’ KLANA PETRA MUBARAK AL-HAJ',
    //     slug:"MASJID-DATO’-KLANA-PETRA-MUBARAK-AL-HAJ",
    //     desc: 'Masjid Dato Klana Petra Mubarak Al-Haj adalah sebuah organisasi yang terlibat dalam pengurusan kebajikan am, khairat kematian, pengurusan kariah setempat dan juga pengurusan dalaman masjid.  Masjid ini juga bergiat aktif menjalankan aktiviti-aktiviti keagamaan (mengelola program-program kuliah mingguan dan pengajian Al-Quran) dan aktiviti kemasyarakatan.\n\n.',
    //     addDesc: 'Selain dari itu, Masjid Dato’ Klana Petra Mubarak Al-Haj juga berfungsi sebagai:\n\n(a) tempat orang-orang Islam beribadah terutamanya mendirikan sembahyang Jumaat dan sembahyang-sembahyang lain.\n\n(b) tempat mengadakan pendidikan Agama Islam dan pendidikan-pendidikan yang memberi faedah kepada ahli kariah yang beragama Islam.\n\n(c) tempat mengadakan sesuatu majlis perayaan yang berkaitan dengan Agama Islam.\n\n(d) tempat mengadakan syarahan atau ceramah agama oleh pensyarah atau penceramah yang dibenarkan oleh Majlis, Jabatan atau Pegawai Agama Islam daerah, mengikut mana yang berkenaan.\n\n(e) pusat penyebaran dakwah, maklumat, peraturan dan arahan daripada Majlis, Jabatan atau Pejabat Agama Islam Daerah.\n\n(f) tempat mengadakan mesyuarat atau perjumpaan berkenaan dengan perkara-perkara yang berkaitan dengan kegiatan keagamaan.\n\n(g) apa-apa jua fungsi lain yang berkaitan dengan kegiatan agama Islam sebagaimana diluluskan oleh Majlis dari semasa ke semasa',
    //     contact:[
    //         {
    //             id: 1,
    //             avt: avtd3,
    //             title:'Nama Pegawai',
    //             item: '',
    //         },

    //         {
    //             id:2,
    //             avt: avtd4,
    //             title:'Email',
    //             item :'mklanapetra@gmail.com',
    //         },
    //         {
    //             id: 3,
    //             avt: avtd5,
    //             title:'No. Telefon',
    //             item :'012-345 6789',

    //         }
    //     ],
    //     jawatan:[
            
    //     ],
    //     news:[
            
    //     ],
    //     state:'Seremban',
    //     address :'Jalan Sungai Landak, 70400 Negeri Sembilan, Seremban'
    // },
    // {
    //     id: 4,
    //     img: img4,
    //     title: 'MADRASAH TAHFIZ AZ-ZEIN',
    //     slug:"MADRASAH-TAHFIZ-AZ-ZEIN",
    //     desc: 'Madrasah Tahfiz Az-Zein (MATAZ) Negeri Sembilan merupakan salah sebuah madrasah yang menguruskan pengajian Islam terbuka buat orang awam yang bernaung sepenuhnya dibawah manhaj Az-Zeiniyyah bimbingan Alim Murobbi Syeikhuna Muhammad Nuruddin bin Marbu al-Banjari al-Makki Hafidzohullah.\n\nInsyaAllah pihak Madrasah Az-Zein Lissuluk Ilallah memohon kepada jemaah dan para pencinta ilmu sekalian untuk menyumbang dalam misi mencari dana pembangunan asrama MADRASAH TAHFIZ AZ-ZEIN (MATAZ) yang akan menempatkan 200 orang pelajar program Alim Kitab (Ikdad) & Tahfiz sepenuh masa di Madrasah Tahfiz Az-Zein (MATAZ), Negeri Sembilan.',
    //     addDesc: 'Tiada Maklumat Tambaan',
    //     contact:[
    //         {
    //             id: 1,
    //             avt: avtd3,
    //             title:'Nama Pegawai',
    //             item: '',
    //         },

    //         {
    //             id:2,
    //             avt: avtd4,
    //             title:'Email',
    //             item :'madrasahazzein@gmail.com',
    //         },
    //         {
    //             id: 3,
    //             avt: avtd5,
    //             title:'No. Telefon',
    //             item :'012-345 6789',

    //         }
    //     ],
    //     jawatan:[
            
    //     ],
    //     news:[
            
    //     ],
    //     state:'Senawang',
    //     address :'Jalan Kiambang 4, 70450 Negeri Sembilan, Senawang'
    // },
    // {
    //     id: 5,
    //     img: img5,
    //     title: 'KOMPLEKS PENGAJIAN ANAK YATIM & WARGA EMAS DARUL WIDAD',
    //     slug:"KOMPLEKS-PENGAJIAN-ANAK-YATIM-&-WARGA-EMAS-DARUL-WIDAD",
    //     desc: 'Darul Widad merupakan sebuah institusi pengajian Islam berorientasikan pondok moden untuk warga emas dan anak yatim mendalami al-Quran dan Fardu Ain berpandukan kitab turath dan tahfiz.',
    //     addDesc: 'Usaha membangunkan generasi Ulul Albab merupakan satu usaha yang murni dan selaras dengan keperluan kemajuan negara Malaysia. Oleh itu usaha membangun Kompleks Anak Yatim Miskin & Warga Emas Darul Widad Linggi adalah untuk melahirkan generasi Ulul Albab yang kontemporari dengan kehendak semasa dunia dan akhirat selain membantu warga emas yang sangat memerlukan sokongan padu daripada semua pihak. Melahirkan anak yatim Warathatul Anbiya dan Ulul Albab, Pembinaan tahfiz anak yatim dan pengajian turath, Membangunkan anak yatim sebagai modal insan kepada pembangunan negara, Menyediakan asrama dan tempat pengajian anak yatim dan warga emas, Khidmat kebajikan untuk anak yatim dan warga emas.',
    //     contact:[
    //         {
    //             id: 1,
    //             avt: avtd3,
    //             title:'Nama Pegawai',
    //             item: '',
    //         },

    //         {
    //             id:2,
    //             avt: avtd4,
    //             title:'Email',
    //             item :'darulwidad@gmail.com',
    //         },
    //         {
    //             id: 3,
    //             avt: avtd5,
    //             title:'No. Telefon',
    //             item :'',

    //         }
    //     ],
    //     jawatan:[
            
    //     ],
    //     news:[
            
    //     ],
    //     state:'Port Dickson',
    //     address :'PT 1195, 71150 Negeri Sembilan, Port Dickson'
    // },
    // {
    //     id: 6,
    //     img: img6,
    //     title: 'SURAU GEDUNG LALANG 162',
    //     slug:"SURAU-GEDUNG-LALANG-162",
    //     desc: 'Surau Gedung Lalang 162 adalah sebuah organisasi yang terlibat dalam pengurusan kebajikan am, khairat kematian, pengurusan kariah setempat dan juga pengurusan dalaman surau.  Surau ini juga bergiat aktif menjalankan aktiviti-aktiviti keagamaan (mengelola program-program kuliah mingguan dan pengajian Al-Quran) dan aktiviti kemasyarakatan.',
    //     addDesc: 'Selain daripada itu Surau Gedung Lalang 162 juga menjalankan aktiviti kemasyarakatan didalam usaha memastikan kesejahteraan warga setempat.',
    //     contact:[
    //         {
    //             id: 1,
    //             avt: avtd3,
    //             title:'Nama Pegawai',
    //             item: '',
    //         },

    //         {
    //             id:2,
    //             avt: avtd4,
    //             title:'Email',
    //             item :'suraugedunglalang@gmail.com',
    //         },
    //         {
    //             id: 3,
    //             avt: avtd5,
    //             title:'No. Telefon',
    //             item :'',

    //         }
    //     ],
    //     jawatan:[
            
    //     ],
    //     news:[
            
    //     ],
    //     state:'Seremban',
    //     address :'Gedong Lalang, 70400 Negeri Sembilan, Seremban'
    // },
    // {
    //     id: 7,
    //     img: img7,
    //     title: 'KOMPLEKS TAHFIZ DARUL ULUM',
    //     slug:"KOMPLEKS-TAHFIZ-DARUL-ULUM",
    //     desc: 'Ia ditubuhkan di atas kesedaran untuk melahirkan ulama yang menguasai dengan sempurna ilmu-ilmu Al-Quran dan As-Sunnah yang akan memimpin masyarakat pada masa hadapan. Kompleks Tahfiz Darul Ulum dibentuk bukan sahaja berorientasikan pendidikan agama malah mengambil kira pendidikan akademik dan kemahiran yang lain sebagaimana yang dituntut oleh ajaran Islam. Ia ditubuhkan dengan tujuan mendidik pelajar-pelajar agar dapat menghayati kebesaran Al-Quran melalui pembacaan dan hafazan Al-Quran secara sistematik melalui ilmu-ilmu berkaitan seperti Bahasa Arab, Tajwid, Qiraat dan Taranum disamping menimba ilmu akademik seperti Matematik, Sains, Bahasa Melayu, Bahasa Inggeris dan lain-lain.',
    //     addDesc: 'No data',
    //     contact:[
    //         {
    //             id: 1,
    //             avt: avtd3,
    //             title:'Nama Pegawai',
    //             item: '',
    //         },

    //         {
    //             id:2,
    //             avt: avtd4,
    //             title:'Email',
    //             item :'komplekstahfizdarululum@gmail.com',
    //         },
    //         {
    //             id: 3,
    //             avt: avtd5,
    //             title:'No. Telefon',
    //             item :'011-1062 2324',

    //         }
    //     ],
    //     jawatan:[
           
    //     ],
    //     news:[
            
    //     ],
    //     state:'Seremban',
    //     address :'Lot 1661, 70400 Negeri Sembilan, Seremban'
    // },
    // {
    //     id: 8,
    //     img: img8,
    //     title: 'PERTUBUHAN ANAK YATIM & MISKIN PEREMPUAN (MAWARKU) SEREMBAN',
    //     slug:"PERTUBUHAN-ANAK-YATIM-&-MISKIN-PEREMPUAN-(MAWARKU)",
    //     desc: 'Rumah Anak Yatim & Miskin Perempuan Mawarku (MAWARKU) diasaskan pada Julai 2018 oleh sahabat-sahabat yang komited terhadap kerja-kerja kebajikan dan kemasyarakatan. Diuruskan oleh Pertubuhan Kebajikan Anak Yatim dan Miskin Perempuan Seremban, sebuah NGO yang berdaftar. MAWARKU sekarang beroperasi di No 616 Jalan Cenderawasih 4, Taman Paroi Jaya, Ampangan, 70400 Seremban, Negeri Sembilan Darul Khusus. Tempat ini merupakan sebuah rumah berbandung dua tingkat.Sistem pembelajaran di MAWARKU adalah aliran akademik. Buat masa ini kami telah menempatkan seramai 12 orang anak yatim atau miskin perempuan di MAWARKU.',
    //     addDesc: 'No Data',
    //     contact:[
    //         {
    //             id: 1,
    //             avt: avtd3,
    //             title:'Nama Pegawai',
    //             item: '',
    //         },

    //         {
    //             id:2,
    //             avt: avtd4,
    //             title:'Email',
    //             item :'mawarkuns@gmail.com',
    //         },
    //         {
    //             id: 3,
    //             avt: avtd5,
    //             title:'No. Telefon',
    //             item :'',

    //         }
    //     ],
    //     jawatan:[
            
    //     ],
    //     news:[
            
    //     ],
    //     state:'Seremban',
    //     address :'616, 74000 Negeri Sembilan, Seremban'
    // },
    // {
    //     id: 9,
    //     img: img9,
    //     title: 'PONDOK DARUL HUDA',
    //     slug:"PONDOK-DARUL-HUDA",
    //     desc: 'Perkampungan Darul Huda adalah perkampungan wakaf yang dimulakan bersama, digerakan bersama dan dimanafaatkan bersama. InsyaAllah, banyak lagi kemudahan wakaf yang akan dibangun untuk faedah bersama.',
    //     addDesc: 'Bermula tahun 2023, PAKAT akan berusahasama dengan syarikat teknologi maklumat DagangTEK Sdn Bhd bagi melaksanakan perkhidmatan Infaq, Waqaf, Khairat dan lain-lain secara digital melalui platform applikasi mudah alih AL-Jariyah.',
    //     contact:[
    //         {
    //             id: 1,
    //             avt: avtd3,
    //             title:'Nama Pegawai',
    //             item: '',
    //         },

    //         {
    //             id:2,
    //             avt: avtd4,
    //             title:'Email',
    //             item :'pondokdarulhuda01@gmail.com',
    //         },
    //         {
    //             id: 3,
    //             avt: avtd5,
    //             title:'No. Telefon',
    //             item :'',

    //         }
    //     ],
    //     jawatan:[
            
    //     ],
    //     news:[
           
    //     ],
    //     state:'Seremban',
    //     address :'No. 740 Pondok Darul Huda'
    // },
    // {
    //     id: 10,
    //     img: img9,
    //     title: ' Masjid Presint 11',
    //     slug:"Masjid-Presint-11",
    //     desc: 'Modul istimewa yang dibangunkan khusus untuk menampung perbelanjaan iftar semasa Ramadhan. Penginfaq boleh menaja kesemua atau sebahagian daripada jumlah harian kos penyediaan makanan iftar bagi institusi yang terlibat.',
    //     addDesc: 'Modul istimewa yang dibangunkan khusus untuk menampung perbelanjaan iftar semasa Ramadhan. Penginfaq boleh menaja kesemua atau sebahagian daripada jumlah harian kos penyediaan makanan iftar bagi institusi yang terlibat.',
    //     contact:[
    //         {
    //             id: 1,
    //             avt: avtd3,
    //             title:'Nama Pegawai',
    //             item: 'Amin',
    //         },

    //         {
    //             id:2,
    //             avt: avtd4,
    //             title:'Email',
    //             item :'amin@gmail.com',
    //         },
    //         {
    //             id: 3,
    //             avt: avtd5,
    //             title:'No. Telefon',
    //             item :'0188776155',

    //         }
    //     ],
    //     jawatan:[
    //             {
    //                 id: 1,
    //                 title:'Pengerusi',
    //                 item: 'Amin Idris',
    //             },
    //     ],
    //     news:[
    //         {
    //             id: 1,
    //             title: "INFAQ IFATR",
    //             desc: "Modul istimewa yang dibangunkan khusus untuk menampung perbelanjaan iftar semasa Ramadhan. Penginfaq boleh menaja ...",
    //             img: "https://al-jariyah.com/assets/images/kempen/banner-infaq-iftar.png",
    //             link: "/institusi/Masjid-Presint-11/INFAQ-IFTAR"
    //         }
    //     ],
    //     state:'Seremban',
    //     address :'No. 5, Jalan P11 B1/4, 61555 Selangor, Kuala Selangor'
    // },

    //demo
    {
        id: 1,
        img: img3,
        title: 'MASJID DATO’ KLANA PETRA MUBARAK AL-HAJ',
        slug:"MASJID-DATO’-KLANA-PETRA-MUBARAK-AL-HAJ",
        desc: 'Masjid Dato Klana Petra Mubarak Al-Haj adalah sebuah organisasi yang terlibat dalam pengurusan kebajikan am, khairat kematian, pengurusan kariah setempat dan juga pengurusan dalaman masjid.  Masjid ini juga bergiat aktif menjalankan aktiviti-aktiviti keagamaan (mengelola program-program kuliah mingguan dan pengajian Al-Quran) dan aktiviti kemasyarakatan.\n\n.',
        addDesc: 'Selain dari itu, Masjid Dato’ Klana Petra Mubarak Al-Haj juga berfungsi sebagai:\n\n(a) tempat orang-orang Islam beribadah terutamanya mendirikan sembahyang Jumaat dan sembahyang-sembahyang lain.\n\n(b) tempat mengadakan pendidikan Agama Islam dan pendidikan-pendidikan yang memberi faedah kepada ahli kariah yang beragama Islam.\n\n(c) tempat mengadakan sesuatu majlis perayaan yang berkaitan dengan Agama Islam.\n\n(d) tempat mengadakan syarahan atau ceramah agama oleh pensyarah atau penceramah yang dibenarkan oleh Majlis, Jabatan atau Pegawai Agama Islam daerah, mengikut mana yang berkenaan.\n\n(e) pusat penyebaran dakwah, maklumat, peraturan dan arahan daripada Majlis, Jabatan atau Pejabat Agama Islam Daerah.\n\n(f) tempat mengadakan mesyuarat atau perjumpaan berkenaan dengan perkara-perkara yang berkaitan dengan kegiatan keagamaan.\n\n(g) apa-apa jua fungsi lain yang berkaitan dengan kegiatan agama Islam sebagaimana diluluskan oleh Majlis dari semasa ke semasa',
        contact:[
            {
                id: 1,
                avt: avtd3,
                title:'Nama Pegawai',
                item: 'Tiada Rekod',
            },

            {
                id:2,
                avt: avtd4,
                title:'Email',
                item :'Tiada Rekod',
            },
            {
                id: 3,
                avt: avtd5,
                title:'No Telefon',
                item :'Tiada Rekod',

            }
        ],
        jawatan:[
            
        ],
        news:[
            
        ],
        state:'Seremban',
        address :'Jalan Sungai Landak, 70400 Negeri Sembilan, Seremban'
    },
    {
        id: 2,
        img: img11,
        title: 'SEMBULAN MOSQUE',
        slug:"SEMBULAN-MOSQUE",
        desc: 'Masjid negeri Sabah terletak di Bulatan Sembulan, Jalan Tunku Abdul Rahman, Kota Kinabalu. Pembinaan masjid ini dimulakan pada tahun 1970 Masihi dan siap sepenuhnya pada tahun 1975 Masihi. Perasmiannya telah disempurnakan oleh Duli Yang Maha Mulia Tuanku Yahya Petra Ibni Almarhum Sultan Ibrahim pada 11 Rejab 1397 Hijri bersamaan 28 Jun 1977 Masihi. Tapak masjid Negeri Sabah ini ialah seluas 14.5 ekar dan berada di lokasi yang strategi kerana dikelilingi dengan kompleks kerajaaan, bangunan USIA, bangunan Yayasan Sabah, bangunan Tabung Haji dan beberapa Institusi pengajian, sekolah, serta kawasan perkampungan Sembulan.\n\n.',
        addDesc: 'Tiada Rekod',
        contact:[
            {
                id: 1,
                avt: avtd3,
                title:'Nama Pegawai',
                item: 'Amin Bin Ahmad',
            },

            {
                id:2,
                avt: avtd4,
                title:'Email',
                item :'amin2002@gmail.com',
            },
            {
                id: 3,
                avt: avtd5,
                title:'No Telefon',
                item :'017784536',

            }
        ],
        jawatan:[
            {
                id: 1,
                title:'Pengerusi',
                item: 'Teng',
            },
        ],
        news:[
            // {
            //     id: 1,
            //     title: "INFAQ IFATR",
            //     desc: "Modul istimewa yang dibangunkan khusus untuk menampung perbelanjaan iftar semasa Ramadhan. Penginfaq boleh menaja ...",
            //     img: "https://al-jariyah.com/assets/images/kempen/banner-infaq-iftar.png",
            //     link: "/institusi/Masjid-Sri-Sendayan/INFAQ-IFTAR"
            // }
        ],
        state:'Kota Kinabalu',
        address :'Jalan Sembulan Lama, Kampung Sembulan, 88100 Kota Kinabalu, Sabah, 88100 Sabah, Kota Kinabalu'
    },

    {
        id: 3,
        img: img12,
        title: 'Masjid Bandar Sg Long',
        slug:"Masjid-Bandar-Sg-Long",
        desc: 'Masjid ini telah ditubuhkan pada tahun 2000 oleh komuniti setempat dan telah dirasmikan oleh Dato Ibrahim Abdullah", "title": "SEJARAH PENUBUHAN MASJID SG LONG.\n\n.',
        addDesc: 'Tiada Rekod',
        contact:[
            {
                id: 1,
                avt: avtd3,
                title:'Nama Pegawai',
                item: 'En Ahmad Bin Abu',
            },

            {
                id:2,
                avt: avtd4,
                title:'Email',
                item :'ahmad@gmail.com',
            },
            {
                id: 3,
                avt: avtd5,
                title:'No Telefon',
                item :'0192883071',

            }
        ],
        jawatan:[
            {
                id: 1,
                title:'Pengerusi',
                item: 'Dato Ibrahim Bin Ahmad',
            },
            {
                id: 1,
                title:'Timbalan Pengerusi',
                item: 'Sabarudin Manaf',
            },
        ],
        news:[
            {
                id: 1,
                title: "Kelas Mengaji Dewasa",
                desc: "Kelas mengaji dewasa dibuka untuk pendaftaran. Kepada kariah yang berminat boleh menghubungi masjid atau admin di talian 03-80807371. Terima Kasih.",
                img: "https://assets-aljariyah.s3.ap-southeast-1.amazonaws.com/institusi/kempen/e2b4f431-1ccf-44db-8327-a4d6e5409d49.jpg",
                link: "/institusi/Masjid-Bandar-Sg-Long/KELAS-MENGAJI-DEWASA"
            }
        ],
        state:'Kajang',
        address :'Jalan Persiaran Sg Long 1, Bandar Sg Long, , 43000 Selangor, Kajang'
    },

    {
        id: 4,
        img: img10,
        title: 'MASJID SABAH',
        slug:"Masjid-Sabah",
        desc: 'Masjid ini telah ditubuhkan pada tahun 2000 oleh komuniti setempat.\n\n.',
        addDesc: 'Tiada Rekod',
        contact:[
            {
                id: 1,
                avt: avtd3,
                title:'Nama Pegawai',
                item: 'KECIK',
            },

            {
                id:2,
                avt: avtd4,
                title:'Email',
                item :'muhdaqmar@dagangtek.com',
            },
            {
                id: 3,
                avt: avtd5,
                title:'No Telefon',
                item :'0134568760',

            }
        ],
        jawatan:[
            {
                id: 1,
                title:'Pengerusi',
                item: 'Kecik',
            },
            
        ],
        news:[
            {
                id: 1,
                title: "Hebah Sembulan Mosque",
                desc: "Keterangan - Hebah Sembulan Mosque.",
                img: "https://al-jariyah.com/assets/images/kempen/banner-infaq-iftar.png",
                link: "/institusi/Masjid-Sabah/Hebah-Sembulan-Mosque"
            }
        ],
        state:'SABAH',
        address :'KOTA BELUD, SABAH, 71300 Sabah, Kota Belud'
    },
    
    

   
];



export default dataBlog ;
